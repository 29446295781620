import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

/**

 @name PageComponent
 @desc Page layout component
 @kind Layouts

 @example
  <f-page>
    <div page-header> header content </div>
    <div page-sidebar> sidebar content </div>
    <div page-content> page main content </div>
  </f-page>

 @example
 <f-page>
   <div page-sidebar> sidebar content </div>
   <div page-content> page main content </div>
 </f-page>

 @example
 <f-page>
   <div page-content> page content (without sidebar) </div>
 </f-page>

 **/

@Component({
  moduleId: module.id,
  selector: 'f-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent implements OnInit, AfterContentInit {
  @ViewChild('header', { static: true }) header: any;
  @ViewChild('sidebar', { static: true }) sidebar: any;
  @ViewChild('content', { static: true }) content: any;

  displayHeader = true;
  displayContent = true;
  displaySidebar = true;

  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.displayHeader = this.header.nativeElement.children.length > 0;
    this.displayContent = this.content.nativeElement.children.length > 0;
    this.displaySidebar = this.sidebar.nativeElement.children.length > 0;
  }
}
