import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  public set(name: string, value: any): void {
    // todo - prefix with user id/name
    localStorage.setItem(name, JSON.stringify(value));
  }

  public get(name: string): any {
    const value = localStorage.getItem(name);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  public clear(): void {
    localStorage.clear();
  }
}
