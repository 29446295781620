import { takeUntil, filter } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { each, map } from 'lodash';
import { DragulaService } from 'ng2-dragula/components/dragula.provider';
import { Subject } from 'rxjs';
import { HelpersService } from '../../../services';

export interface SimpleCrudItem {
  name: string;
  position?: number;
}

export interface SimpleCrudItemAction {
  name: string;
  label: string;
}

@Component({
  selector: 'f-simple-crud',
  templateUrl: './simple-crud.component.html',
  styleUrls: ['./simple-crud.component.scss'],
  providers: [DragulaService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleCrudComponent implements OnInit, OnDestroy, OnChanges {
  private ngUnsubscribe = new Subject();

  @Input() items: SimpleCrudItem[] = [];
  @Input() actions: SimpleCrudItemAction[] = [];
  @Input() title = '';
  @Input() titleEditable = false;
  @Input() addFormVisible = false;
  @Input() draggable = true;
  @Input() redirectOnClickTo: string = null;

  @Output() reorder = new EventEmitter<SimpleCrudItem[]>();
  @Output() addItem = new EventEmitter<SimpleCrudItem>();
  @Output() updateItem = new EventEmitter<{ item: any; name: string }>();
  @Output() removeItem = new EventEmitter<{ item: any }>();
  @Output() remove = new EventEmitter<void>();
  @Output() update = new EventEmitter<{ item: any; name: string }>();
  @Output() action = new EventEmitter<{ name: string; tag: any }>();

  @ViewChild('input', { static: false }) input;
  @ViewChild('inputTitle', { static: false }) inputTitle;

  dragContainerName = 'items-' + Math.random();
  public dragulaItems = [];
  public editing = false;
  public titleUpdated;

  constructor(
    private dragulaService: DragulaService,
    private viewContainerRef: ViewContainerRef,
    private cdRef: ChangeDetectorRef,
    private helpersService: HelpersService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initDragula();
    // console.warn('this.items', this.items)
  }

  ngOnChanges(changes) {
    if (changes.items && changes.items.currentValue) {
      this.dragulaItems = [...changes.items.currentValue];
    }
    if (changes.title && !this.editing) {
      this.titleUpdated = this.title;
    }
    if (changes.title && this.editing) {
      this.hideEditForm();
    }
  }

  ngOnDestroy() {
    this.dragulaService.destroy(this.dragContainerName);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initDragula(): void {
    if (this.dragulaService.find(this.dragContainerName)) {
      this.dragulaService.destroy(this.dragContainerName);
    }

    //  options help:
    //  https://github.com/bevacqua/dragula#dragulacontainers-options
    this.dragulaService.setOptions(this.dragContainerName, {
      mirrorContainer: this.viewContainerRef.element.nativeElement,
      moves: (el, container, target) => {
        return this.helpersService.isInParentWithClass(target, 'drag_handle');
      },
    });

    this.dragulaService.dropModel
      .pipe(
        filter(([bagName]) => bagName === this.dragContainerName),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(value => {
        this.reorder.emit(this.dragulaItems);
      });
  }

  //  add new item

  public newItemName = '';

  public displayAddForm(): void {
    this.addFormVisible = true;
    this.newItemName = '';
    setTimeout(() => {
      if (this.input) {
        this.input.focus();
      }
    });
  }

  public hideAddForm(): void {
    this.addFormVisible = false;
    this.newItemName = '';
  }

  public addNewItem(name): void {
    if (!name) {
      return;
    }
    this.newItemName = '';
    this.addItem.emit(name);
    this.hideAddForm();
  }

  //  edit

  handleItemUpdate(item, name) {
    // console.log('handleItemUpdate', item, name)
    this.updateItem.emit({
      item,
      name: name,
    });
  }

  handleItemRemove(item) {
    // console.log('handleItemRemove, item', item)
    this.removeItem.emit(item);
  }

  //  title edit

  removeTitle() {
    this.remove.emit();
  }

  editTitle(item) {
    this.editing = true;
    setTimeout(() => {
      if (this.inputTitle) {
        this.inputTitle.focus();
      }
    });
  }

  hideEditForm() {
    this.editing = false;
    this.titleUpdated = this.title;
  }

  updateTitle(name) {
    if (!name) {
      return;
    }
    // console.log('handleItemRemove, item', item)
    this.update.emit(name);
  }

  //  other events

  redirectToTagPage(item) {
    if (this.redirectOnClickTo) {
      this.router.navigate([this.redirectOnClickTo, item.$key]);
    }
  }
}
