import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { MenuService } from '../../../services';

import { AuthService } from '../../../services/auth.service';
import { ContactFormDialogComponent } from '../contact-form-dialog/contact-form-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'f-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() userName = '???';

  @Output() onLogout = new EventEmitter();

  @HostBinding('class.expanded') get expanded() {
    return this.menuService.$isOpen.getValue();
  }

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    public menuService: MenuService
  ) {}

  ngOnInit() {}

  displayContactForm(): void {
    // @todo - move to helpers (getDialogWidth('short'))
    let width = window.innerWidth / 2;
    if (width < 600) {
      width = window.innerWidth > 600 ? 600 : window.innerWidth;
    }
    const dialogRef = this.dialog.open(ContactFormDialogComponent, {
      width: `${width}px`,
      data: {
        user: {
          name: this.authService.user.displayName,
          email: this.authService.user.email,
        },
      },
    });
    dialogRef.afterClosed().subscribe(message => {
      this.menuService.toggleMenu(false);
    });
  }
}
