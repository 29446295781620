import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { fbPixelTrack } from '../../shared/helpers';
import { AuthService } from '../../services/auth.service';

@Component({
  moduleId: module.id,
  selector: 'f-login',
  templateUrl: './login.component.html',
  styleUrls: ['../pre-login-page.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('password', { static: false }) inputPassword: any;
  @ViewChild('username', { static: false }) inputUsername: any;

  error: any = null;
  loading = false;
  loadingGoogle = false;
  loadingFacebook = false;
  loadingGithub = false;

  constructor(public authService: AuthService, public router: Router) {}

  ngOnInit() {}

  submitForm($event) {
    this.login(
      $event,
      this.inputUsername.nativeElement.value,
      this.inputPassword.nativeElement.value
    );
  }

  login($event, username, password) {
    $event.preventDefault();
    this.loading = true;
    this.authService
      .login(username, password)
      .then(_ => {
        this.loading = false;
      })
      .catch(error => {
        this.error = error;
        this.loading = false;
      });
  }

  loginWithGoogle() {
    this.loadingGoogle = true;
    this.authService
      .loginWithGoogle()
      .then(_ => {
        this.loadingGoogle = false;
        fbPixelTrack('CompleteRegistration', {
          value: 1,
        });
      })
      .catch(error => {
        this.error = error;
        this.loadingGoogle = false;
      });
  }

  loginWithFacebook() {
    this.loadingFacebook = true;
    this.authService
      .loginWithFacebook()
      .then(_ => {
        this.loadingFacebook = false;
        fbPixelTrack('CompleteRegistration', {
          value: 2,
        });
      })
      .catch(error => {
        this.error = error;
        this.loadingFacebook = false;
      });
  }

  loginWithGithub() {
    this.loadingGithub = true;
    this.authService
      .loginWithGithub()
      .then(_ => {
        this.loadingGithub = false;
        fbPixelTrack('CompleteRegistration', {
          value: 3,
        });
      })
      .catch(error => {
        this.error = error;
        this.loadingGithub = false;
      });
  }

  //  helpers

  jumpToPasswordInput() {
    this.inputPassword.nativeElement.focus();
  }
}
