import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { WeightDialogComponent } from './weight-dialog/weight-dialog.component';
import { SharedModule } from '@app/shared/shared.module';
import { TagDialogComponent } from './tag-dialog/tag-dialog.component';

@NgModule({
  declarations: [TagDialogComponent, WeightDialogComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  entryComponents: [TagDialogComponent, WeightDialogComponent],
})
export class DialogsModule {}
