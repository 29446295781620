import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  moduleId: module.id,
  selector: 'f-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  user$;
  notifications$;
  notificationsLoaded$;

  constructor(
    private authService: AuthService,
    private notificationsService: NotificationsService
  ) {
    this.user$ = this.authService.user$;
    this.notifications$ = this.notificationsService.items$;
    this.notificationsLoaded$ = this.notificationsService.loaded$;

    // this.notifications$.subscribe(data => console.log('notifications$', data))
  }

  ngOnInit() {}

  logout() {
    this.authService.logout();
  }
}
