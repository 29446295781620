import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { sortBy } from 'lodash';

import { AuthService } from './auth.service';
import { FirebaseCollectionService } from './firebase-collection.service';

@Injectable()
export class NotificationsService extends FirebaseCollectionService {
  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase
  ) {
    super('db/notifications', _authService, _db);
  }

  protected get dbPath() {
    return `/${this.collectionName}`;
  }

  parseData(items) {
    return items.map(item => this.parseItem(item));
  }

  parseItem(item) {
    return item;
  }

  sortData(items) {
    return sortBy(items, item => {
      return -new Date(item._created_at);
    });
  }
}
