import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { each, filter, find, flatMap, map, sortBy } from 'lodash';

import { FirebaseCollectionService } from './firebase-collection.service';
import { AuthService } from './auth.service';

@Injectable()
export class CartService extends FirebaseCollectionService {
  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase
  ) {
    super('cart', _authService, _db);
    // console.warn('CartService::constructor')
  }

  parseData(items) {
    return items.map(item => this.parseItem(item));
  }

  parseItem(item) {
    return item;
  }

  sortData(items) {
    return sortBy(items, item => {
      let prefix;
      let date;
      if (item.checked) {
        prefix = '1';
        //  sort checked items => last `checked` on top
        date = 99999999999999 - +new Date(item._checked_at);
      } else {
        prefix = '0';
        //  sort active items => last added on top
        date = 99999999999999 - +new Date(item._created_at);
      }
      return `${prefix}${date}`;
    });
  }

  removeCheckedItems() {
    each(this.items$.getValue(), item => {
      if (item.checked) {
        this.remove(item.$key);
      }
    });
  }

  removeItemByIngredientId(ingredientId) {
    return new Promise((resolve, reject) => {
      each(this.items$.getValue(), item => {
        if (item.ingredient_id === ingredientId) {
          this.remove(item.$key);
        }
      });
      resolve();
    });
  }

  getItemByIngredientId(ingredientId) {
    return find(this.items$.getValue(), item => {
      return item.ingredient_id === ingredientId;
    });
  }
}
