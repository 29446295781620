import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { find, indexOf } from 'lodash';

import { LocalStorageService } from '../../../services/local-storage.service';

export interface FilterSetTagItemType {
  name: string;
  value: any;
}

@Component({
  moduleId: module.id,
  selector: 'f-filter-set-tag',
  templateUrl: './filter-set-tag.component.html',
  styleUrls: ['./filter-set-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSetTagComponent implements OnInit, OnChanges {
  @Input() filters: Array<FilterSetTagItemType> = [];
  @Input() multiple = true;
  @Input() displayHeaderButtons = true;
  @Input() name;
  @Input() label = '';
  @Input() selectedFilters: Array<FilterSetTagItemType> = [];

  @Output() onFiltersChange = new EventEmitter();

  private dbId;
  public isCollapsed;

  constructor(private ls: LocalStorageService) {}

  ngOnInit() {
    this.dbId = 'filters_' + this.name;
    //  expand filters by default, but remember state between user visits
    this.isCollapsed = this.ls.get(this.dbId + '_isCollapsed') || false;
    // console.log('FilterSetComponent::ngOnInit selectedFilters', this.selectedFilters)
    // console.log('FilterSetComponent::ngOnInit this.filters', this.filters)
  }

  ngOnChanges(changes) {
    // console.log('changes', changes);
  }

  emitChange(selectedFilters) {
    // console.log('FilterSetComponent::emitChange selectedFilters', selectedFilters)
    if (!selectedFilters) {
      selectedFilters = this.selectedFilters;
    }
    this.onFiltersChange.emit(selectedFilters);
  }

  toggle(clickedFilter, $event) {
    let selectedFilters;
    if (this.multiple) {
      //  select clicked filter when all are selected
      selectedFilters = [clickedFilter];
    } else {
      //  toggle checked
      if (
        find(
          this.selectedFilters,
          filter => filter.value === clickedFilter.value
        )
      ) {
        selectedFilters = this.selectedFilters.filter(filter => {
          return filter.value !== clickedFilter.value;
        });
      } else {
        selectedFilters = [...this.selectedFilters, clickedFilter];
      }
    }

    // this.saveSelection()
    this.emitChange(selectedFilters);
  }

  isSelected(item) {
    // console.log('1find(this.selectedFilters, filter => filter.value === item.value)', find(this.selectedFilters, filter => filter.value === item.value));
    return find(this.selectedFilters, filter => filter.value === item.value);
  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
    this.ls.set(this.dbId + '_isCollapsed', this.isCollapsed);
  }
}
