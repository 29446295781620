import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

import { compact, each, find, map, sortBy } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { sanitizeForFirebase } from '../shared/helpers/sanitize';

import { AuthService } from './auth.service';

export interface FirebaseDatabeItem {
  $key: string;
  _created_at: string;
  _updated_at: string;
  _overwritten_at: string;
}

@Injectable()
export abstract class FirebaseCollectionService {
  protected dbUserDataPath: string;
  protected collectionName: string;
  protected ngUnsubscribe;
  protected user: any; // @todo - add type (UserInfo)
  public firebaseList; // : AngularFireList<any>
  public firebaseItem; // : AngularFireObject<any>

  public loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public items$: BehaviorSubject<any> = new BehaviorSubject([]);
  public firebaseListSubscriber;
  public firebaseItemSubscriber;
  public itemLoaded$: BehaviorSubject<any> = new BehaviorSubject(false);
  public item$: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    collectionName: string,
    private authService: AuthService,
    private db: AngularFireDatabase
  ) {
    this.ngUnsubscribe = new Subject();
    // console.warn('FirebaseCollectionService::constructor', collectionName)
    this.collectionName = collectionName;

    this.authService.user$.subscribe(user => {
      // console.warn('FirebaseCollectionService::user change', user);
      if (user) {
        this.init();
      } else {
        this.reset();
      }
    });
  }

  reset() {
    // console.warn(`FirebaseCollectionService::${this.collectionName}::reset`)
    if (this.firebaseListSubscriber) {
      this.firebaseListSubscriber.unsubscribe();
    }
    if (this.firebaseItemSubscriber) {
      this.firebaseItemSubscriber.unsubscribe();
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  init() {
    // console.warn(`FirebaseCollectionService::${this.collectionName}::init`)
    this.user = this.authService.user;
    this.dbUserDataPath = this.dbPath;
    // console.log('FirebaseCollectionService.dbUserDataPath', this.dbUserDataPath)

    this.firebaseList = this.db.list(this.dbUserDataPath, ref => {
      if (this.dbOptions) {
        return ref
          .orderByChild(this.dbOptions.orderByChild)
          .equalTo(this.dbOptions.equalTo);
      }
      return ref;
    });

    this.firebaseListSubscriber = this.firebaseList
      .snapshotChanges()
      .subscribe(data => {
        const res = data.map((item: any) => {
          const $key = item.key;
          const value = item.payload.val();
          const _data = Object.assign({}, { $key }, value);
          return _data;
        });

        // console.log(`firebaseListSubscriber::${this.collectionName}::firebaseList.subscribe`, res)
        this.items$.next(this.parseData(this.sortData(res)));
      });

    const firstSub = this.firebaseList.snapshotChanges().subscribe(data => {
      // console.log(`FirebaseCollectionService::${this.collectionName}::firebaseList.first`, data)
      this.loaded$.next(true);
      firstSub.unsubscribe();
    });
  }

  public parseOnCollectionUpdate(collection: FirebaseCollectionService): void {
    collection.items$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(_ => {
      this.items$.next(this.sortData(this.parseData(this.items$.getValue())));
    });
  }

  protected get dbPath() {
    return `/userData/${sanitizeForFirebase(this.user.email)}___${
      this.user.uid
    }/${this.collectionName}`;
  }

  protected get dbOptions() {
    return {
      orderByChild: '_deleted_at',
      equalTo: null,
    };
  }

  abstract parseData(data);

  abstract parseItem(data);

  protected sortData(data) {
    return sortBy(data, item => {
      if (item.position) {
        return parseInt(item.position, 10);
      }
      return '' + (item.name || '').toLowerCase();
    });
  }

  addItem(item: any) {
    const _item = Object.assign(item, {
      _created_at: new Date().toISOString(),
    });
    return this.firebaseList.push(_item);
  }

  // update (diff)
  update($key: string, data: any) {
    return this.firebaseList.update($key, data);
  }

  // replace
  set($key: string, data: any) {
    return this.firebaseList.set($key, data);
  }

  remove($key: string) {
    if (!$key) {
      return;
    }
    return this.firebaseList.remove($key);
  }

  getItem(itemId: string) {
    const itemFound = find(
      this.items$.getValue(),
      item => item.$key === itemId
    );
    // console.log('getItem() => itemId, itemFound', itemId, itemFound)
    // console.log('getItem() => items', this.items$.getValue())
    return itemFound;
  }

  deleteItem(key: string) {
    // console.warn('!!!! deleteItem => remove !!!! ')
    return this.remove(key);
  }

  //  firebaseItem

  resetItem() {
    //  reset item data
    this.itemLoaded$.next(false);
    this.item$.next({});
    if (this.firebaseItemSubscriber) {
      this.firebaseItemSubscriber.unsubscribe();
    }
  }

  loadItem(key: string): void {
    // console.warn('loadItem', key)
    this.resetItem();
    //  get item data from firebase and susbscribe to it
    this.firebaseItem = this.db.object(`${this.dbUserDataPath}/${key}`);

    const itemSub = this.firebaseItem.snapshotChanges().subscribe(data => {
      // console.log(`loadItem::${key}::snapshotChanges.first => loaded true`)
      this.itemLoaded$.next(true);
      itemSub.unsubscribe();
    });

    this.firebaseItemSubscriber = this.firebaseItem
      .snapshotChanges()
      .subscribe(snapshot => {
        // console.log('snapshot', snapshot);
        const $key = snapshot.key;
        const value = snapshot.payload.val();

        let res = Object.assign({}, { $key }, value);
        // console.log(`====> loadItem::${key}::snapshot`, snapshot)
        if (res.$key === null) {
          res = {};
        }
        this.item$.next(this.parseItem(res));
      });
  }

  updateItem(value: Object) {
    const _value = Object.assign({}, value, {
      _updated_at: new Date().toISOString(),
    });
    return this.firebaseItem.update(_value);
  }

  setItem(value: any) {
    const _value = Object.assign({}, value, {
      _updated_at: new Date().toISOString(),
      _overwritten_at: new Date().toISOString(),
    });
    return this.firebaseItem.set(_value);
  }

  //  helpers

  fillRefs(ids: Array<string>, service: FirebaseCollectionService) {
    // console.group(`fillRefs`)
    // console.log('ids', ids)
    const refs = compact(
      map(ids, id => {
        return service.getItem(id);
      })
    );
    // console.log('refs', refs)
    // console.groupEnd()
    return refs;
  }
}
