import {
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { OverlayService } from './overlay.service';

@Component({
  selector: 'f-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit, OnDestroy {
  @HostBinding('class.visible') _visible: boolean;

  @HostListener('click')
  onClick() {
    this.overlayService.hide({
      fromOverlayClick: true,
    });
  }

  private sub;
  private visible = false;

  constructor(private overlayService: OverlayService) {}

  ngOnInit() {
    this.sub = this.overlayService.visible$.subscribe(visible => {
      this._visible = visible;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
