import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'f-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements OnInit, OnChanges {
  @Input() message: string;
  @Input() type?: 'error' | 'success' | 'warning';

  @HostBinding('class.error') _error: boolean;
  @HostBinding('class.success') _success: boolean;
  @HostBinding('class.warning') _warning: boolean;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this._error = this.type === 'error';
    this._success = this.type === 'success';
    this._warning = this.type === 'warning';
  }
}
