import { ElementRef, Injectable } from '@angular/core';
import { get, isNumber } from 'lodash';
import { LocalStorageService } from '../local-storage.service';

@Injectable()
export class ScrollPositionService {
  constructor(private ls: LocalStorageService) {}

  public saveScrollPosition(
    viewId: string,
    scrollElement: ElementRef | number
  ): void {
    if (!scrollElement) {
      this.ls.set(`lastScrollPosition-${viewId}`, 0);
      return;
    }
    let lastScrollPosition;
    if (isNumber(scrollElement)) {
      lastScrollPosition = scrollElement;
    } else {
      lastScrollPosition = get(scrollElement, 'nativeElement.scrollTop');
    }
    this.ls.set(`lastScrollPosition-${viewId}`, lastScrollPosition);
  }

  public restoreScrollPosition(
    viewId: string,
    scrollElement: ElementRef
  ): void {
    const lastScrollPosition = this.ls.get(`lastScrollPosition-${viewId}`);
    if (lastScrollPosition && scrollElement) {
      scrollElement.nativeElement.scrollTop = lastScrollPosition;
      this.ls.set(`lastScrollPosition-${viewId}`, 0);
    }
  }
}
