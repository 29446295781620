import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { TagService } from '@app/services';
import { MessagesService } from '@app/services';
import { TagGroupService } from '@app/services';
import { trimValues } from '@app/shared/helpers';

@Component({
  selector: 'f-tag-dialog',
  templateUrl: './tag-dialog.component.html',
  styleUrls: ['./tag-dialog.component.scss'],
})
export class TagDialogComponent implements OnInit {
  form;
  loading;
  groups;
  creatingNewGroup = false;

  constructor(
    private fb: FormBuilder,
    private messagesService: MessagesService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<TagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tagService: TagService,
    private tagGroupService: TagGroupService
  ) {
    this.groups = data.groups;
  }

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      group: [''],
      groupName: [''],
    });
  }

  public submitForm() {
    if (!this.form.valid) {
      return false;
    }
    const formValue = trimValues(this.form.value);
    this.loading = true;
    if (formValue.group === '--new--' && formValue.groupName) {
      this.createNewGroup(formValue.groupName).then(data => {
        return this.saveNewTagAndClose({
          name: formValue.name,
          group: data.key,
        }).then(() => {
          this.onCancel();
        });
      });
    } else {
      this.saveNewTagAndClose({
        name: formValue.name,
        group: formValue.group,
      }).then(() => {
        this.onCancel();
      });
    }
  }

  private createNewGroup(name) {
    return this.tagGroupService.addItem({ name });
  }

  private saveNewTagAndClose(tag) {
    return this.tagService.addItem(tag);
  }

  public onCancel() {
    this.form.reset();
    this.dialogRef.close(null);
  }

  //  events

  public onGroupChange($event) {
    if (this.form.value.group === '--new--') {
      this.creatingNewGroup = true;
    } else {
      this.creatingNewGroup = false;
    }
  }

  public cancelCreatingNewGroup() {
    this.creatingNewGroup = false;
  }
}
