import { PageComponent } from '../components-layouts/page/page.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { BoxComponent } from './box/box.component';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ColorPickerDialogComponent } from './color-picker-dialog/color-picker-dialog.component';
import { ContactFormDialogComponent } from './contact-form-dialog/contact-form-dialog.component';
import { DescriptionComponent } from './description/description.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FilterPickerDialogComponent } from './filter-picker-dialog/filter-picker-dialog.component';
import { FilterSearchComponent } from './filter-search/filter-search.component';
import { FilterSetTagComponent } from './filter-set-tag/filter-set-tag.component';
import { FilterSetComponent } from './filter-set/filter-set.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HeaderComponent } from './header/header.component';
import { IconComponent } from './icon/icon.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { InputDebounceComponent } from './input-debounce/input-debounce.component';
import { LoadingComponent } from './loading/loading.component';
import { LogoComponent } from './logo/logo.component';
import { MessageComponent } from './message/message.component';
import { OverlayComponent } from './overlay/overlay.component';
import { OverlayService } from './overlay/overlay.service';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PellComponent } from './pell/pell.component';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';
import { SearchFilterBarComponent } from './search-filter-bar/search-filter-bar.component';
import { SimpleCrudItemComponent } from './simple-crud-item/simple-crud-item.component';
import { SimpleCrudComponent } from './simple-crud/simple-crud.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TagPickerDialogComponent } from './tag-picker-dialog/tag-picker-dialog.component';
import { TagComponent } from './tag/tag.component';
import { TaggerComponent } from './tagger/tagger.component';
import { SimplePickerDialogComponent } from './simple-picker-dialog/simple-picker-dialog.component';

export const SHARED_COMPONENTS = [
  InputDebounceComponent,
  LoadingComponent,
  EmptyStateComponent,
  FilterSetComponent,
  FilterSetTagComponent,
  FilterSearchComponent,
  PageComponent,
  ButtonComponent,
  LogoComponent,
  TaggerComponent,
  HeaderComponent,
  DropdownComponent,
  AutocompleteComponent,
  CheckboxComponent,
  BoxComponent,
  SpinnerComponent,
  MessageComponent,
  IconComponent,
  OverlayComponent,
  SearchFilterBarComponent,
  PageHeaderComponent,
  TagComponent,
  SimpleCrudComponent,
  PellComponent,
  ContactFormDialogComponent,
  DescriptionComponent,
  FileUploadComponent,
  ImageUploadComponent,
  GalleryComponent,
  PromptDialogComponent,
  SimpleCrudItemComponent,
  ColorPickerDialogComponent,
  TagPickerDialogComponent,
  FilterPickerDialogComponent,
  SimplePickerDialogComponent,
];

export const SHARED_DIALOGS = [
  ContactFormDialogComponent,
  PromptDialogComponent,
  ColorPickerDialogComponent,
  TagPickerDialogComponent,
  FilterPickerDialogComponent,
  SimplePickerDialogComponent,
];

export * from './input-debounce/input-debounce.component';
export * from './loading/loading.component';
export * from './empty-state/empty-state.component';
export * from './filter-set/filter-set.component';
export * from './filter-set-tag/filter-set-tag.component';
export * from './filter-search/filter-search.component';
export * from './button/button.component';
export * from './tagger/tagger.component';
export * from './header/header.component';
export * from './dropdown/dropdown.component';
export * from './autocomplete/autocomplete.component';
export * from './checkbox/checkbox.component';
export * from './box/box.component';
export * from './spinner/spinner.component';
export * from './message/message.component';
export * from './icon/icon.component';
export * from './overlay/overlay.component';
export * from './search-filter-bar/search-filter-bar.component';
export * from './tag/tag.component';
export * from './page-header/page-header.component';
export * from './simple-crud/simple-crud.component';
export * from './pell/pell.component';
export * from './contact-form-dialog/contact-form-dialog.component';
export * from './description/description.component';
export * from './file-upload/file-upload.component';
export * from './image-upload/image-upload.component';
export * from './gallery/gallery.component';
export * from './simple-crud-item/simple-crud-item.component';
export * from './color-picker-dialog/color-picker-dialog.component';
export * from './tag-picker-dialog/tag-picker-dialog.component';
export * from './filter-picker-dialog/filter-picker-dialog.component';
export * from './simple-picker-dialog/simple-picker-dialog.component';

//  services

export const SHARED_SERVICES = [OverlayService];

export * from './overlay/overlay.service';
