import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OverlayService {
  public visible$ = new BehaviorSubject(false);

  private clickCallback;

  constructor() {}

  show(
    options: {
      clickCallback?: Function;
    } = {}
  ) {
    this.visible$.next(true);
    this.clickCallback = options.clickCallback;
  }

  hide(
    options: {
      fromOverlayClick: boolean;
    } = {
      fromOverlayClick: false,
    }
  ) {
    this.visible$.next(false);

    if (options.fromOverlayClick && this.clickCallback) {
      this.clickCallback();
    }
  }
}
