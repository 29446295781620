import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { init } from 'pell';
import { fromEvent as observableFromEvent } from 'rxjs';

import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

export const PELL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PellComponent),
  multi: true,
};

@Component({
  selector: 'f-pell',
  template: `
    <div class="pell-container" #inputRef></div>
  `,
  styleUrls: ['./pell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PELL_VALUE_ACCESSOR],
})
export class PellComponent
  implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder = '';
  @Input() id = '';
  @Input() value = '';
  @Input() delay = 250;
  @Input() disabled = false;
  @Input() formControl: FormControl;

  @Output() valueChannge: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputRef', { static: false }) inputRef;

  _value = '';
  editor;
  onChange;
  onTouch;

  constructor(private elementRef: ElementRef) {
    this._value = this.value;

    // this.bindKeyUpEvent()
    this.bindTouchEvent();
  }

  ngOnInit() {
    // this.initPell()
    // console.log('this.formControl ngOnInit')
  }

  ngAfterViewInit() {
    this.initPell();
    // console.log('this.formControl ngAfterViewInit')
  }

  reset() {
    this.inputRef.nativeElement.value = '';
  }

  initPell() {
    this.editor = init({
      element: this.elementRef.nativeElement,
      onChange: html => {
        this.valueChannge.emit(html);

        if (this.onChange) {
          this.onChange(html);
        }
      },
    });
    this.editor.content.innerHTML = this._value;
  }

  // events

  bindKeyUpEvent() {
    const eventStream = observableFromEvent(
      this.elementRef.nativeElement,
      'keyup'
    ).pipe(
      map(() => this.inputRef.nativeElement.value),
      debounceTime(this.delay),
      distinctUntilChanged()
    );
    eventStream.subscribe(input => {
      this.valueChannge.emit(input);

      if (this.onChange) {
        this.onChange(input);
      }
    });
  }

  bindTouchEvent() {
    const eventStreamTouch = observableFromEvent(
      this.elementRef.nativeElement,
      'touch'
    );
    eventStreamTouch.subscribe(input => {
      console.warn('onTouch');
      if (this.onTouch) {
        this.onTouch(input);
      }
    });
  }

  //  ControlValueAccessor

  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
