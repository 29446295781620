import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { sortBy } from 'lodash';

import { AuthService } from './auth.service';
import { DietService } from './diet.service';
import { FirebaseCollectionService } from './firebase-collection.service';
import { IngredientsPublicService } from './ingredients-public.service';
import { IngredientsService } from './ingredients.service';
import { TagService } from './tag.service';

@Injectable()
export class MealService extends FirebaseCollectionService {
  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase,
    private dietService: DietService,
    private ingredientsService: IngredientsService,
    private ingredientsPublicService: IngredientsPublicService,
    private tagService: TagService
  ) {
    super('meal', _authService, _db);
    // console.warn('MealService::constructor')
  }

  parseData(items) {
    // console.warn('MealService::parseData')
    return items.map(item => this.parseItem(item));
  }

  parseItem(item) {
    //  get diet_ids from dietService
    item.diets = this.fillRefs(item.diet_ids, this.dietService);

    //  get tags from service
    item.tags = this.fillRefs(item.tag_ids, this.tagService);

    //  get ingredientsDescription from ingredientsService
    // console.log('item.ingredients_list', item.ingredients_list);
    let ingredientsList = (item.ingredients_list || [])
      .map(ingredient => {
        let ingredientObject;
        if (ingredient.isPublic) {
          ingredientObject = this.ingredientsPublicService.getItem(
            ingredient.id
          );
        } else {
          ingredientObject = this.ingredientsService.getItem(ingredient.id);
        }
        if (ingredientObject) {
          ingredient.ref = ingredientObject;
          ingredient.ingredientName = ingredientObject.name;
          ingredient.ingredientDescription = ingredientObject.description;
        } else {
          ingredient.ref = {};
        }
        return ingredient;
      })
      .filter(ingredient => !!ingredient);

    //  sort ingredients by position
    ingredientsList = sortBy(
      ingredientsList,
      (ingredient, index) => ingredient.position || Number.MAX_VALUE
    );

    item.ingredients = ingredientsList;

    return item;
  }
}
