import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { fbPixelTrack } from '../../shared/helpers';
import { AuthService } from '../../services/auth.service';

@Component({
  moduleId: module.id,
  selector: 'f-register',
  templateUrl: './register.component.html',
  styleUrls: ['../pre-login-page.scss'],
})
export class RegisterComponent implements OnInit {
  @ViewChild('password', { static: false }) inputPassword;
  @ViewChild('username', { static: false }) inputUsername;

  public error: any = null;
  public loading = false;

  agreed = false;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {}

  submitForm($event) {
    this.validateForm();
    if (!this.error) {
      this.register(
        $event,
        this.inputUsername.nativeElement.value,
        this.inputPassword.nativeElement.value
      );
    }
  }

  validateForm() {
    this.error = false;

    if (!this.agreed) {
      this.error = {
        message: 'Proszę zaakceptować regulamin i politykę prywatności',
      };
    }
  }

  register($event, email, password) {
    $event.preventDefault();
    this.loading = true;
    this.authService
      .register(email, password)
      .then(_ => {
        this.loading = false;
        fbPixelTrack('CompleteRegistration');
      })
      .catch(error => {
        this.loading = false;
        this.error = error;
      });
  }

  //  helpers

  jumpToPasswordInput() {
    this.inputPassword.nativeElement.focus();
  }
}
