import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private ls: LocalStorageService,
    private router: Router
  ) {
    // console.warn('AuthGuard::constructor')
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // console.log('AuthGuard::canActivate state', state);
    // console.log('AuthGuard::canActivate next.url', next.url[0].path)
    // console.log('AuthGuard::canActivate this.authService.isLoggedIn()',
    //   this.authService.isLoggedIn())

    if (!this.authService.isLoggedIn()) {
      // console.warn('redirect')
      //  save url for later use (redirect user after login)
      this.ls.set('router.next.url', state.url);
      this.router.navigate([`/logowanie`]);
    }

    return this.authService.isLoggedIn();
  }
}
