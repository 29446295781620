import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FirebaseStorageService } from '../../../services';

@Component({
  selector: 'f-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput;

  @Output() uploaded = new EventEmitter();

  user;
  loading;

  constructor(
    private firebaseStorage: FirebaseStorageService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  fileChange($event) {
    // console.log('$event', $event)
    // console.log('$event.target.files', $event.target.files)
    const files = $event.target.files;
    const file = files[0];
    if (this.validateFile(file)) {
      this.loading = true;
      this.firebaseStorage
        .uploadFile(file)
        .then(data => {
          this.uploaded.emit(data);
          this.loading = false;
        })
        .catch(error => {
          // console.warn('this.firebaseStorage.uploadFile error from promise', error)
          this.loading = false;
          this.toastr.warning(
            `Wystąpił błąd podczas wgrywania pliku. Proszę spróbować ponownie później.`,
            'Błąd wgrywania pliku'
          );
        });
    }
  }

  validateFile(file) {
    const size = file.size / Math.pow(10, 6);
    // check size
    if (size > 5) {
      this.toastr.warning(
        'Maksymalny rozmiar pliku wynosi 5MB. Wybierz inny plik.',
        'Błąd wgrywania pliku'
      );
      return false;
    }
    // check type
    if (file.type.split('/')[0] !== 'image') {
      this.toastr.warning(
        'Możliwe jest wgrywanie tylko plików graficznych. Wybierz inny plik.',
        'Błąd wgrywania pliku'
      );
      return false;
    }

    return true;
  }
}
