import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'f-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() label = 'Wybierz:';
  @Input() placeholder = 'Wpisz nazwę';
  @Input() newElementName = 'element';
  @Input() displaySuggestionsOnFocus = false;
  @Input() suggestions: Array<{
    name: string;
    description: string;
    value: any;
  }> = [];

  //  on select one iem
  @Output() selectItem = new EventEmitter();
  //  when user want to add new item
  @Output() addItem = new EventEmitter();

  query = new FormControl();

  public displaySuggestions = false;
  private filteredSuggestions;
  private allSugestionsCounter = 0;
  private highlightedIndex = 0;
  private ngUnsubscribe = new Subject();

  constructor() {}

  ngOnInit() {
    this.filteredSuggestions = this.suggestions;
    this.allSugestionsCounter = this.filteredSuggestions.length;
    // console.log('this.filteredSuggestions', this.filteredSuggestions)
  }

  ngAfterViewInit() {
    this.bindInputEvents();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  //  sugestion events

  onItemClick($event, item) {
    this.selectItem.emit(item);
    this.reset();
  }

  onNewItemClick($event, value) {
    if (!value || !value.length) {
      return;
    }
    this.addItem.emit(value);
    this.reset();
  }

  //  input events

  onFocus() {
    // console.log('this.filteredSuggestions', this.filteredSuggestions)
    if (this.displaySuggestionsOnFocus) {
      this.displaySuggestions = true;
    }
  }

  onInput(value) {
    if (value.length < 2 && !this.displaySuggestionsOnFocus) {
      this.displaySuggestions = false;
      return;
    }

    //  filter suggestions based on user input
    this.filteredSuggestions = this.suggestions.filter(item => {
      if (item && item.name) {
        if ((!value || value.length === 0) && this.displaySuggestionsOnFocus) {
          return item;
        }
        const textSplitted = value.toLowerCase().split(' ');
        const itemName = item.name.toLowerCase();
        let found = true;
        textSplitted.forEach(textToCheck => {
          found = found && itemName.indexOf(textToCheck) > -1;
        });
        return found;
      } else {
        return false;
      }
    });

    // console.log('this.filteredSuggestions', this.filteredSuggestions)

    //  display max 10 suggestions at once
    if (this.filteredSuggestions.length > 10) {
      this.allSugestionsCounter = this.filteredSuggestions.length;
      this.filteredSuggestions = this.filteredSuggestions.slice(0, 10);
    } else {
      this.allSugestionsCounter = 0;
    }

    //  reset highlightedIndex
    this.highlightedIndex = 0;

    // console.log('this.filteredSuggestions', this.filteredSuggestions)
    this.displaySuggestions = true;
  }

  onEnter($event) {
    if (this.filteredSuggestions.length) {
      this.selectItem.emit(this.filteredSuggestions[this.highlightedIndex]);
    } else {
      this.addItem.emit(this.query.value);
    }
    this.reset();
  }

  onEscape() {
    this.hideDropdown();
  }

  onArrowDown($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    if (this.highlightedIndex >= this.filteredSuggestions.length - 1) {
      this.highlightedIndex = 0;
    } else {
      this.highlightedIndex++;
    }
  }

  onArrowUp($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    if (this.highlightedIndex < 1) {
      this.highlightedIndex = this.filteredSuggestions.length - 1;
    } else {
      this.highlightedIndex--;
    }
  }

  //  privates

  private bindInputEvents() {
    this.query.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe(data => {
        this.onInput(data);
      });
  }

  private hideDropdown() {
    this.displaySuggestions = false;
  }

  private reset() {
    this.hideDropdown();
    this.query.setValue('');
  }
}
