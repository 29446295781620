import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateFirebaseMessage',
})
export class TranslateFirebaseMessagePipe implements PipeTransform {
  private translates = [
    {
      firebaseMessageMatch:
        'The password is invalid or the user does not have a password',
      translatedMessage:
        'Hasło jest nieprawidłowe lub użytkownik nie posiada hasła.',
    },
    {
      firebaseMessageMatch: 'Password should be at least 6 characters',
      translatedMessage: 'Hasło powinno składać się z minimum 6 znaków.',
    },
    {
      firebaseMessageMatch:
        'There is no user record corresponding to this identifier',
      translatedMessage:
        'Nie znaleziono konta przypisanego do podanego adresu email.',
    },
    {
      firebaseMessageMatch:
        'The email address is already in use by another account',
      translatedMessage: 'Adres email jest już zarejestrowany. ',
    },
    {
      firebaseMessageMatch: 'The email address is badly formatted',
      translatedMessage: 'Adres email jest niepoprawny. ',
    },
    {
      firebaseMessageMatch:
        'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.',
      translatedMessage:
        'Adres email jest już zarejestrowany z użyciem innych danych. Zaloguj si używając serwisu, który jest przypisany do tego adresu.',
    },
  ];

  transform(value: any, args?: any): any {
    // console.log('value', value);
    let translated = null;

    this.translates.forEach(translateObject => {
      // console.log('translateObject', translateObject);
      if (
        value.message.indexOf(translateObject.firebaseMessageMatch) > -1 &&
        !translated
      ) {
        translated = translateObject.translatedMessage;
      }
    });

    // console.log('translated', translated);

    return translated || value.message;
  }
}
