import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { each, filter, find, flatMap, map, sortBy } from 'lodash';
import { FirebaseCollectionService } from './firebase-collection.service';

import { AuthService } from './auth.service';

@Injectable()
export class IngredientsHomeService extends FirebaseCollectionService {
  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase
  ) {
    super('ingredient-home', _authService, _db);
    // console.warn('IngredientsHomeService::constructor')
  }

  parseData(items) {
    return items.map(item => this.parseItem(item));
  }

  parseItem(item) {
    return item;
  }

  removeItemByIngredientId(ingredientId) {
    // console.warn('IngredientsHomeService::removeItemByIngredient')
    return new Promise((resolve, reject) => {
      each(this.items$.getValue(), item => {
        if (item.ingredient_id === ingredientId) {
          this.remove(item.$key);
        }
      });
      resolve();
    });
  }

  getItemByIngredientId(ingredientId) {
    // console.warn('IngredientsHomeService::getItemByIngredient')
    return find(this.items$.getValue(), item => {
      return item.ingredient_id === ingredientId;
    });
  }

  addItemFromIngredient(ingredient) {
    // console.warn('IngredientsHomeService::addItemFromIngredient', ingredient)
    return this.addItem({
      ingredient_id: ingredient.$key,
      name: ingredient.name,
      isPublic: ingredient.isPublic || false,
    });
  }
}
