import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { OverlayService } from '../components/overlay/overlay.service';

@Directive({
  selector: '[fOverlayHideOnClick]',
})
export class OverlayHideOnClickDirective {
  constructor(private overlayService: OverlayService) {}

  @HostListener('click')
  onMouseClick() {
    this.overlayService.hide({
      fromOverlayClick: true,
    });
  }
}
