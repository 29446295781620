import {
  ElementRef,
  HostListener,
  Directive,
  OnInit,
  AfterViewInit,
  Input,
} from '@angular/core';

@Directive({
  selector: 'textarea[fAutosize]',
  exportAs: 'fAutosize',
})
export class AutosizeDirective implements AfterViewInit {
  private el: HTMLElement;
  private _minHeight: string;
  private _maxHeight: string;

  @Input('minHeight')
  get minHeight(): string {
    return this._minHeight;
  }
  set minHeight(val: string) {
    this._minHeight = val;
    this.updateMinHeight();
  }

  @Input('maxHeight')
  get maxHeight(): string {
    return this._maxHeight;
  }
  set maxHeight(val: string) {
    this._maxHeight = val;
    this.updateMaxHeight();
  }

  constructor(public element: ElementRef) {
    this.el = element.nativeElement;
  }

  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjust();
  }

  @HostListener('focus', ['$event.target'])
  onFocus(textArea: HTMLTextAreaElement): void {
    this.adjust();
  }

  ngAfterViewInit(): void {
    this.adjust();
  }

  adjust(): void {
    // perform height adjustments after input changes, if height is different
    if (
      this.el.style.height ==
      this.element.nativeElement.scrollHeight + 'px'
    ) {
      return;
    }
    this.element.nativeElement.style.overflow = 'auto';
    this.element.nativeElement.style.height = 'auto';
    this.element.nativeElement.style.height =
      this.element.nativeElement.scrollHeight + 'px';
  }

  updateMinHeight(): void {
    this.el.style.minHeight = this._minHeight + 'px';
  }

  updateMaxHeight(): void {
    this.el.style.maxHeight = this._maxHeight + 'px';
  }
}
