import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DialogsModule } from '@app/dialogs/dialogs.module';
import { SettingsService } from '@app/services/settings.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { JasperoConfirmationsModule } from '@jaspero/ng-confirmations';
import * as Raven from 'raven-js';

//  3rd party modules
import { ToastrModule } from 'ngx-toastr';

//  app modules and globals
import { environment } from '@environments/environment';
import { AppComponent } from './app.component';

//  global routes
import { AppRoutes } from './app.routes';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { HomePageComponent } from './views/home/home-page.component';

//  all services.. (@todo - refactor, create service module ?)
import { APP_SERVICES } from './services';
import { SHARED_SERVICES } from './shared/components';

import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';

//  all modules required by app
import { UserModule } from './user/user.module';
import { SharedModule } from './shared/shared.module';

if (environment.production) {
  Raven.config(
    'https://61b8c6eff3be4a8b94add4894f669963@sentry.io/190504'
  ).install();
}

export class RavenErrorHandler extends ErrorHandler {
  handleError(err: any): void {
    if (environment.production) {
      Raven.captureException(err);
    } else {
      super.handleError(err);
    }
  }
}

@NgModule({
  imports: [
    //  angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
    }),
    //  material modules
    MatDialogModule,
    //  3rd party modules
    JasperoConfirmationsModule.forRoot(),
    ToastrModule.forRoot(),
    //  firebase modules
    AngularFireModule.initializeApp(
      environment.firebase /*, FIREBASE_AUTH_CONFIG*/
    ),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    //  global routes
    RouterModule.forRoot(AppRoutes),
    // application modules
    SharedModule,
    UserModule,
    DialogsModule,
  ],
  declarations: [AppComponent, NotFoundComponent, HomePageComponent],
  providers: [
    APP_SERVICES,
    SHARED_SERVICES,
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    AuthGuard,
    AuthService,
    SettingsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
