import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MessagesService } from '../../../services/messages.service';
import { trimValues } from '../../helpers/trim';

export interface PromptDialogComponentData {
  title: string;
  submitLabel: string;
  onSave: () => Promise<any>;
}

@Component({
  selector: 'f-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
})
export class PromptDialogComponent implements OnInit {
  form;
  loading;

  title;
  submitLabel;
  onSave;

  constructor(
    private fb: FormBuilder,
    private messagesService: MessagesService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<PromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PromptDialogComponentData
  ) {
    this.title = data.title;
    this.submitLabel = data.submitLabel;
    this.onSave = data.onSave;
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      input: ['', Validators.required],
    });
  }

  submitForm() {
    if (!this.form.valid) {
      return false;
    }
    const formValue = trimValues(this.form.value);
    if (this.onSave) {
      this.loading = true;
      this.onSave(formValue.input).then(() => {
        this.dialogRef.close(formValue.input);
      });
    } else {
      this.dialogRef.close(formValue.input);
    }
  }

  onCancel() {
    this.form.reset();
    this.dialogRef.close(null);
  }
}
