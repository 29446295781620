import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { FilterPickerDialogComponent } from '../filter-picker-dialog/filter-picker-dialog.component';
import { FilterSetItemType } from '../filter-set/filter-set.component';

export interface FilterSetType {
  filters: Array<FilterSetItemType>;
  id: string;
  header: string;
  selectedFilters: Array<FilterSetType>;
  multiple: boolean;
}

@Component({
  selector: 'f-search-filter-bar',
  templateUrl: './search-filter-bar.component.html',
  styleUrls: ['./search-filter-bar.component.scss'],
})
export class SearchFilterBarComponent implements OnInit, OnChanges {
  @Input() searchLabel = '';
  @Input() searchInitialValue = '';
  @Input() filters: Array<FilterSetType> = [];
  @Input() pulseTriggerIcon = false;
  @Input() disableSearch = false;

  @Output() searchChange = new EventEmitter();
  @Output() filtersChange = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    // console.log('SearchFilterBarComponent::ngOnInit filters', this.filters)
  }

  ngOnChanges() {
    // console.log('SearchFilterBarComponent::ngOnChanges filters', this.filters)
  }

  openTagPicker() {
    const dialogRef = this.dialog.open(FilterPickerDialogComponent, {
      width: `500px`,
      data: {
        filters: this.filters,
      },
    });
    dialogRef.afterClosed().subscribe(selectedFilters => {
      console.log('result', selectedFilters);
      this.filtersChange.emit(selectedFilters);
    });
  }
}
