import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

import { AuthService } from './auth.service';
import { FirebaseCollectionService } from './firebase-collection.service';
import { CartService } from './cart.service';
import { IngredientsHomeService } from './ingredients-home.service';
import { TagService } from './tag.service';

@Injectable()
export class IngredientsService extends FirebaseCollectionService {
  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase,
    private cartService: CartService,
    private tagService: TagService,
    private ingredientsHomeService: IngredientsHomeService
  ) {
    super('ingredient', _authService, _db);
    // console.warn('IngredientsService::constructor')

    this.parseOnCollectionUpdate(this.ingredientsHomeService);
    this.parseOnCollectionUpdate(this.cartService);
  }

  parseData(items) {
    // console.warn('IngredientsService::parseData')
    return items.map(item => this.parseItem(item));
  }

  parseItem(item) {
    //  add some info from other services
    item.isInCart = this.isIngredientInCart(item);
    item.isInHome = this.isIngredientInHome(item);

    //  get tags from service
    item.tags = this.fillRefs(item.tag_ids, this.tagService);

    return item;
  }

  isIngredientInCart(ingredient) {
    return this.cartService.getItemByIngredientId(ingredient.$key);
  }

  isIngredientInHome(ingredient) {
    return this.ingredientsHomeService.getItemByIngredientId(ingredient.$key);
  }
}
