import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[fTooltip]',
})
export class TooltipDirective implements OnDestroy {
  tooltip: any;
  elemPosition: any;
  tooltipOffset = 8;

  @Input() tooltipText = '';
  @Input() tooltipPlacement = 'bottom';
  @Input() tooltipDelay = 0;
  @Input() tooltipShowDelay = 250;
  @Input() tooltipHideDelay = 300;
  @Input() tooltipZIndex = false;

  constructor(private elementRef: ElementRef) {}

  ngOnDestroy() {
    if (this.tooltip) {
      this.tooltip.remove();
    }
  }

  @HostListener('focusin')
  @HostListener('mouseenter')
  onMouseEnter() {
    this.getElemPosition();
    document.body.appendChild(this.createElem());
    this.setPosition();
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  @HostListener('click')
  onMouseLeave() {
    this.removeElem();
  }

  getElemPosition() {
    this.elemPosition = this.elementRef.nativeElement.getBoundingClientRect();
  }

  createElem() {
    this.tooltipShowDelay = this.tooltipDelay || this.tooltipShowDelay;
    if (this.tooltip) {
      this.tooltip.remove();
    }
    this.tooltip = document.createElement('span');
    this.tooltip.className += 'f-tooltip f-tooltip-' + this.tooltipPlacement;
    this.tooltip.textContent = this.tooltipText;
    if (this.tooltipZIndex) {
      this.tooltip.style.zIndex = this.tooltipZIndex;
    }

    setTimeout(() => {
      this.tooltip.className += ' f-tooltip-show';
    }, this.tooltipShowDelay);

    return this.tooltip;
  }

  removeElem() {
    this.tooltip.classList.remove('f-tooltip-show');
    setTimeout(() => {
      this.tooltip.remove();
    }, this.tooltipHideDelay);
  }

  setPosition() {
    const elemHeight = this.elementRef.nativeElement.offsetHeight;
    const elemWidth = this.elementRef.nativeElement.offsetWidth;
    const tooltipHeight = this.tooltip.clientHeight;
    const tooltipWidth = this.tooltip.offsetWidth;

    if (this.tooltipPlacement === 'top') {
      this.tooltip.style.top =
        this.elemPosition.top +
        window.scrollY -
        (tooltipHeight + this.tooltipOffset) +
        'px';
    }

    if (this.tooltipPlacement === 'bottom') {
      this.tooltip.style.top =
        this.elemPosition.top +
        window.scrollY +
        elemHeight +
        this.tooltipOffset +
        'px';
    }

    if (this.tooltipPlacement === 'top' || this.tooltipPlacement === 'bottom') {
      this.tooltip.style.left =
        this.elemPosition.left + elemWidth / 2 - tooltipWidth / 2 + 'px';
    }

    if (this.tooltipPlacement === 'left') {
      this.tooltip.style.left =
        this.elemPosition.left - tooltipWidth - this.tooltipOffset + 'px';
    }

    if (this.tooltipPlacement === 'right') {
      this.tooltip.style.left =
        this.elemPosition.left + elemWidth + this.tooltipOffset + 'px';
    }

    if (this.tooltipPlacement === 'left' || this.tooltipPlacement === 'right') {
      this.tooltip.style.top =
        this.elemPosition.top +
        window.scrollY +
        elemHeight / 2 -
        this.tooltip.clientHeight / 2 +
        'px';
    }
  }
}
