import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'f-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
})
export class BoxComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
