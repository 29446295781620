import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { compact, each, find, map, sortBy } from 'lodash';

import { AuthService } from './auth.service';

@Injectable()
export abstract class FirebaseStorageService {
  protected user: any; // @todo - add type (UserInfo)

  storageRef;

  constructor(private authService: AuthService) {
    this.authService.user$.subscribe(user => {
      if (user) {
        this.user = user;
        this.init();
      } else {
        this.reset();
      }
    });
  }

  reset() {}

  init() {
    this.storageRef = firebase.storage().ref();
  }

  generateImageId(file) {
    return `${+new Date()}.${file.name}`;
  }

  generateRefId(imageId) {
    return `meal-image/${this.user.uid}/${imageId}`;
  }

  getFileUrl(imageId) {
    const ref = this.generateRefId(imageId);
    return this.storageRef.child(ref).getDownloadURL();
  }

  uploadFile(file) {
    const imageId = this.generateImageId(file);
    const ref = this.generateRefId(imageId);
    const uploadTask = this.storageRef.child(ref).put(file);

    return new Promise((resolve, reject) => {
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        (snapshot: any) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // console.log('Upload is paused')
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // console.log('Upload is running')
              break;
          }
        },
        error => {
          // Handle unsuccessful uploads
          // console.error('Failed to upload file: ', ref)
          reject();
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          const downloadURL = uploadTask.snapshot.downloadURL;
          // console.log('downloadURL', downloadURL)
          // console.log('ref', ref)
          resolve({
            file: file,
            url: downloadURL,
            imageId: imageId,
          });
        }
      );
    });
  }

  removeFile(imageId) {
    const ref = this.generateRefId(imageId);
    const storageRef = this.storageRef.child(ref);

    return new Promise((resolve, reject) => {
      storageRef
        .delete()
        .then(function() {
          // File deleted successfully
          resolve();
        })
        .catch(function(error) {
          // Uh-oh, an error occurred!
          reject();
        });
    });
  }
}
