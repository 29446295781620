import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'f-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit, OnChanges {
  @HostBinding('class.checked') _checked: boolean;
  @HostBinding('class.partially-checked') _partiallyChecked: boolean;

  @Input() checked: boolean;
  @HostBinding('class.disabled') @Input() disabled: boolean;
  @Input() partiallyChecked: boolean;
  @Input() label: string;

  @Output() toggleCheckbox = new EventEmitter();

  @HostListener('click') onClick() {
    if (!this.disabled) {
      this.toggleCheckbox.emit();
    }
  }

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this._checked = this.checked;
    this._partiallyChecked = this.partiallyChecked;
  }
}
