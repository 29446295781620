import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FirebaseStorageService } from '@app/services';

@Component({
  selector: 'f-image-storage',
  templateUrl: './image-storage.component.html',
  styleUrls: ['./image-storage.component.scss'],
})
export class ImageStorageComponent implements OnInit {
  @Input() set photo(value) {
    this.getPhotoUrl(value.imageId).then(srcUrl => {
      this.srcUrl = srcUrl;
      this.changeDetectorRef.detectChanges();
    });
  }

  srcUrl: string;
  data: any;

  constructor(
    private firebaseStorageService: FirebaseStorageService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  getPhotoUrl(imageId) {
    return this.firebaseStorageService.getFileUrl(imageId);
  }
}
