import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { FirebaseCollectionService } from './firebase-collection.service';

export interface Group {
  name: string;
  position: number;
}

@Injectable()
export class TagGroupService extends FirebaseCollectionService {
  public groupedItems$: BehaviorSubject<object> = new BehaviorSubject({});

  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase
  ) {
    super('tag-group', _authService, _db);
    // console.warn('TagService::constructor')
  }

  get dbOptions() {
    return null;
  }

  parseData(items: Group[]): Group[] {
    return items.map(item => this.parseItem(item));
  }

  parseItem(item: Group): Group {
    return item;
  }
}
