import { HomePageComponent } from './views/home/home-page.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';

export const AppRoutes = [
  {
    path: '',
    pathMath: 'full',
    redirectTo: '/logowanie',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: HomePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'carts',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/carts/carts.module').then(module => module.CartsModule),
  },
  {
    path: 'diets',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/diets/diets.module').then(module => module.DietsModule),
  },
  {
    path: 'ingredients',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/ingredients/ingredients.module').then(
        module => module.IngredientsModule
      ),
  },
  {
    path: 'meals',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/meals/meals.module').then(module => module.MealsModule),
  },
  {
    path: 'diary',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/diary/diary.module').then(module => module.DiaryModule),
  },
  {
    path: 'tag',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/tags/tags.module').then(module => module.TagsModule),
  },
  { path: '**', component: NotFoundComponent },
];
