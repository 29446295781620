import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { SimpleCrudItemAction } from '../simple-crud/simple-crud.component';

@Component({
  selector: 'f-simple-crud-item',
  templateUrl: './simple-crud-item.component.html',
  styleUrls: ['./simple-crud-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleCrudItemComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() deletable = false;
  @Input() actions: SimpleCrudItemAction[] = [];

  @Output() update = new EventEmitter<string>();
  @Output() delete = new EventEmitter<void>();
  @Output() action = new EventEmitter<string>();

  @ViewChild('input', { static: false }) inputRef: any;

  public editing = false;
  public updatedName = '';

  private ngUnsubscribe = new Subject();

  constructor() {}

  ngOnInit() {
    this.updatedName = this.name;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  editItem() {
    this.editing = true;
    setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.focus();
      }
    });
  }

  cancelEditItem() {
    this.updatedName = this.name;
    this.editing = false;
  }

  updateItemName(name) {
    if (!name) {
      return;
    }
    this.update.emit(name);
    this.editing = false;
  }

  removeItem() {
    this.delete.emit();
  }
}
