import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

import { AuthService } from './auth.service';
import { FirebaseCollectionService } from './firebase-collection.service';

/*
 Settings category with items:

 app - global app settings
  * mark_ingredient_in_home_on_cart_item_complete - true/false

 layout - user specific layout settings (colors etc)
 */

@Injectable()
export class SettingsService extends FirebaseCollectionService {
  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase
  ) {
    super('settings', _authService, _db);
    // console.warn('SettingsService::constructor')
  }

  get dbOptions() {
    return null;
  }

  parseData(items) {
    // console.warn('SettingsService::parseData')
    return items.map(item => this.parseItem(item));
  }

  parseItem(item) {
    return Object.assign({}, item);
  }

  //  item helpers (settings active category)

  isOn(id) {
    return (this.item$.getValue() || {})[id];
  }

  toggleOption(id) {
    const value = {};
    value[id] = !(this.item$.getValue() || {})[id];
    this.updateItem(value);
  }
}
