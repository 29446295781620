import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

import { AuthService } from './auth.service';
import { CartService } from './cart.service';
import { FirebaseCollectionService } from './firebase-collection.service';
import { IngredientsHomeService } from './ingredients-home.service';

@Injectable()
export class IngredientsPublicService extends FirebaseCollectionService {
  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase,
    private cartService: CartService,
    private ingredientsHomeService: IngredientsHomeService
  ) {
    super('db/ingredients', _authService, _db);
    // console.warn('IngredientsService::constructor')

    this.parseOnCollectionUpdate(this.ingredientsHomeService);
    this.parseOnCollectionUpdate(this.cartService);
  }

  get dbOptions() {
    return null;
  }

  protected get dbPath() {
    return `/${this.collectionName}`;
  }

  parseData(items) {
    // console.warn('IngredientsService::parseData')
    return items.map(item => this.parseItem(item));
  }

  parseItem(item) {
    //  add some info from other services
    item.isInCart = this.isIngredientInCart(item);
    item.isInHome = this.isIngredientInHome(item);

    return item;
  }

  isIngredientInCart(ingredient) {
    return this.cartService.getItemByIngredientId(ingredient.$key);
  }

  isIngredientInHome(ingredient) {
    return this.ingredientsHomeService.getItemByIngredientId(ingredient.$key);
  }
}
