import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatCardModule,
  MatListModule,
  MatMenuModule,
  MatSlideToggleModule,
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { VirtualScrollModule } from 'angular2-virtual-scroll';
import { DragulaModule } from 'ng2-dragula/components/dragular.module';
import { ToastrModule } from 'ngx-toastr';

import { SHARED_COMPONENTS, SHARED_DIALOGS } from './components';
import { SHARED_DIRECTIVES } from './directives';
import { SHARED_PIPES } from './pipes';
import { ImageStorageComponent } from './components/image-storage/image-storage.component';

@NgModule({
  imports: [
    //  3rdparty
    ToastrModule.forRoot(),
    VirtualScrollModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatListModule,
    MatCardModule,
    CommonModule,
    DragulaModule,
    // angular
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_PIPES,
    ...SHARED_DIALOGS,
    ImageStorageComponent,
  ],
  exports: [
    // 3rdparty
    ToastrModule,
    VirtualScrollModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatListModule,
    MatCardModule,
    //  angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //  own / app / f17
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_PIPES,
    ImageStorageComponent,
  ],
  entryComponents: [...SHARED_DIALOGS],
})
export class SharedModule {}
