import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'f-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  @Input() photos;
  @Input() mainPhoto;

  @Output() photoClick = new EventEmitter();
  @Output() deleteClick = new EventEmitter();
  @Output() mainClick = new EventEmitter();
  @Output() addClick = new EventEmitter();
  @Output() addPhoto = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
