import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '@app/services/auth.service';
import {
  FirebaseCollectionService,
  FirebaseDatabeItem,
} from '@app/services/firebase-collection.service';
import { sortBy } from 'lodash';

export interface DiaryWeightData {
  weight: number;
  date: string; // date in iso format
}

export interface DiaryCustomData {
  text: string;
  date: string; // date in iso format
}

export interface DiaryItem<T = DiaryWeightData | DiaryCustomData>
  extends FirebaseDatabeItem {
  type: 'weight' | 'custom';
  data: T;
}

export interface UiDiaryWeighItem extends FirebaseDatabeItem {
  type: 'weight' | 'custom';
  data: DiaryWeightData;
  diff: string;
  diffPercentage: number;
  isLess: boolean;
  isMore: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DiaryService extends FirebaseCollectionService {
  private lastWeightItem: DiaryItem<DiaryWeightData>;

  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase
  ) {
    super('diary', _authService, _db);
  }

  parseData(items) {
    // console.warn('DiaryService::parseData', items)
    return (
      items
        //  use reverse to properly weight items in order
        .reverse()
        .map(_item => {
          const item = this.parseItem(_item);
          if (item.type === 'weight') {
            return this.parseWeightItem(item);
          }
          return item;
        })
        .reverse()
    );
  }

  parseItem(item) {
    // console.warn('DiaryService::parseItem', item)
    return item;
  }

  protected sortData(data: DiaryItem[]): any[] {
    return sortBy(data, item => {
      return -new Date(item.data.date);
    });
  }

  private parseWeightItem(_item: any): UiDiaryWeighItem {
    let item = _item;
    if (this.lastWeightItem) {
      const diff = this.getWeightDiff(item, this.lastWeightItem);
      item = {
        ...item,
        diff: diff.toFixed(2),
        diffPercentage: this.getWeightDiffPercentage(item, this.lastWeightItem),
        isLess: diff < 0,
        isMore: diff > 0,
      };
    }

    this.lastWeightItem = item;
    return item;
  }

  private getWeightDiff(
    item: DiaryItem<DiaryWeightData>,
    prevItem: DiaryItem<DiaryWeightData>
  ) {
    return item.data.weight - prevItem.data.weight;
  }

  private getWeightDiffPercentage(
    item: DiaryItem<DiaryWeightData>,
    prevItem: DiaryItem<DiaryWeightData>
  ) {
    if (!prevItem) {
      return null;
    }
    const diff = this.getWeightDiff(item, prevItem);
    return ((diff / prevItem.data.weight) * 100).toFixed(2);
  }
}
