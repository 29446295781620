import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[fDropdownHideOnClick]',
})
export class DropdownHideOnClickDirective implements OnInit {
  constructor() {}

  ngOnInit() {}

  @HostListener('click')
  onMouseClick() {
    //  @todo
  }
}
