import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

// TODO - refactor this (and probably other components) to not use classes
// set by parent component - it should be done via @Input and @HostBinding

@Component({
  moduleId: module.id,
  selector: 'f-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() loading = false;
  @Input() disabled = false;

  @Output() onClick = new EventEmitter();

  @HostBinding('class.loading') _loading: boolean;
  @HostBinding('class.disabled') _disabled: boolean;

  @HostListener('click') click() {
    if (this.disabled || this.loading) {
      return;
    }
    this.onClick.emit();
  }

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this._loading = this.loading;
    this._disabled = this.loading || this.disabled;
  }
}
