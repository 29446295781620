import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import pl from 'date-fns/locale/pl';
import { differenceInDays, distanceInWords, format } from 'date-fns';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  static formatDistance(date) {
    return distanceInWords(new Date(date), new Date(), {
      locale: pl,
      addSuffix: true,
    });
  }

  static format(date, option) {
    return format(new Date(date), option, {
      locale: pl,
    });
  }

  transform(date, option: string) {
    switch (option) {
      case 'app':
        if (differenceInDays(new Date(), new Date(date)) > 2) {
          return FormatDatePipe.format(date, 'DD.MM.YYYY');
        }
        return FormatDatePipe.formatDistance(date);
      case 'distance':
        return FormatDatePipe.formatDistance(date);
      default:
        return FormatDatePipe.format(date, option);
    }
  }
}
