import { takeUntil } from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { filter, find, indexOf } from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { TagService } from '../../../services';
import { TagGroupService } from '../../../services/tag-group.service';

@Component({
  selector: 'f-tag-picker-dialog',
  templateUrl: './tag-picker-dialog.component.html',
  styleUrls: ['./tag-picker-dialog.component.scss'],
})
export class TagPickerDialogComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  loaded$;

  allTags;
  tags;
  groupedTags;
  groups;
  selectedIds;

  constructor(
    private dialogRef: MatDialogRef<TagPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public tagService: TagService,
    public tagGroupService: TagGroupService,
    public router: Router
  ) {
    this.selectedIds = data.selectedIds || [];
  }

  ngOnInit() {
    this.loaded$ = combineLatest(
      this.tagService.loaded$,
      this.tagGroupService.loaded$,
      (l1, l2) => l1 && l2
    );

    this.tagGroupService.items$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.groups = data;
      });
    this.tagService.items$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.allTags = data;
        this.tags = data;
      });
    this.tagService.groupedItems$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.groupedTags = data;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onCancel() {
    this.dialogRef.close(null);
  }

  public isSelected(tag) {
    return indexOf(this.selectedIds, tag.$key) > -1;
  }

  public toggleSelect(tag) {
    if (this.isSelected(tag)) {
      this.selectedIds = filter(this.selectedIds, tagId => tagId !== tag.$key);
    } else {
      this.selectedIds.push(tag.$key);
    }
  }

  public save() {
    this.dialogRef.close(this.selectedIds);
  }

  get isTagsListEmpty(): boolean {
    return this.allTags.length === 0;
  }

  public closeAndRedirectToTags() {
    this.onCancel();
    this.router.navigate(['/tag']);
  }
}
