import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { isUndefined } from 'util';

@Component({
  moduleId: module.id,
  selector: 'f-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['../pre-login-page.scss'],
})
export class ForgotPasswordPageComponent implements OnInit {
  public success = null;
  public error = null;
  public loading = false;

  constructor(public authService: AuthService) {}

  ngOnInit() {}

  forgot($event, email) {
    this.success = null;
    this.error = null;
    this.loading = true;

    $event.preventDefault();
    this.authService
      .sendPasswordResetEmail(email)
      .then(() => {
        this.loading = false;
        this.success = true;
      })
      .catch(error => {
        this.loading = false;
        this.error = error;
      });
  }
}
