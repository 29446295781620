import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PromptDialogComponentData } from '@app/shared/components/prompt-dialog/prompt-dialog.component';
import { trimValues } from '@app/shared/helpers';

@Component({
  selector: 'f-weight-dialog',
  templateUrl: './weight-dialog.component.html',
  styleUrls: ['./weight-dialog.component.scss'],
})
export class WeightDialogComponent implements OnInit {
  form: FormGroup;
  loading: boolean;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<WeightDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PromptDialogComponentData
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      weight: ['', Validators.required],
      date: [new Date()],
    });
  }

  submitForm() {
    if (!this.form.valid) {
      return false;
    }
    const formValue = trimValues(this.form.value);
    formValue.date = formValue.date.toISOString();
    this.dialogRef.close(formValue);
  }

  onCancel() {
    this.form.reset();
    this.dialogRef.close(null);
  }
}
