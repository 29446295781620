import { Injectable } from '@angular/core';

@Injectable()
export class HelpersService {
  constructor() {}

  handleFirebaseError(error) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // console.warn('---- Error Code---- ', errorCode)
    // console.warn('---- Error Mmg ---- ', errorMessage)
  }

  public isInParentWithClass(target, className: string): boolean {
    let result = false;
    let el = target;
    do {
      if (el.classList.contains(className)) {
        result = true;
        break;
      }
    } while ((el = el.parentElement));
    return result;
  }
}
