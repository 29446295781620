import { Injectable } from '@angular/core';
import { isNull } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MenuService {
  public $isOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  public toggleMenu(state: boolean = null): void {
    if (!isNull(state)) {
      this.$isOpen.next(state);
    } else {
      const currentState = this.$isOpen.getValue();
      this.$isOpen.next(!currentState);
    }
  }
}
