import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/guards/auth.guard';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SettingsComponent } from './settings/settings.component';
import { ForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';

const routes: Routes = [
  {
    path: 'logowanie',
    component: LoginComponent,
  },
  {
    path: 'login',
    redirectTo: 'logowanie',
  },
  {
    path: 'reset-hasla',
    component: ForgotPasswordPageComponent,
  },
  {
    path: 'forgot',
    redirectTo: 'reset-hasla',
  },
  {
    path: 'rejestracja',
    component: RegisterComponent,
  },
  {
    path: 'register',
    redirectTo: 'rejestracja',
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
