import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { filter, find, indexOf } from 'lodash';

@Component({
  selector: 'f-simple-picker-dialog',
  templateUrl: './simple-picker-dialog.component.html',
  styleUrls: ['./simple-picker-dialog.component.scss'],
})
export class SimplePickerDialogComponent implements OnInit, OnDestroy {
  items;
  title;

  constructor(
    private dialogRef: MatDialogRef<SimplePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.items = data.items || [];
    this.title = data.title || 'Wybierz';
  }

  ngOnInit() {}

  ngOnDestroy() {}

  public handleSelect(item) {
    this.dialogRef.close(item);
  }

  public onCancel() {
    this.dialogRef.close(null);
  }
}
