import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { ToastrService } from 'ngx-toastr';
import { DietService } from '../../services/diet.service';

import { AuthService } from '../../services/auth.service';
import { SettingsService } from '../../services/settings.service';

@Component({
  moduleId: module.id,
  selector: 'f-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public settings$;
  public settingsLoaded$;

  constructor(
    private auth: AuthService,
    private settingsService: SettingsService,
    private dietService: DietService,
    private confirmationService: ConfirmationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.settingsService.loadItem('app');
    this.settings$ = this.settingsService.item$;
    this.settingsLoaded$ = this.settingsService.itemLoaded$;
  }

  toggleOption(id) {
    this.settingsService.toggleOption(id);
  }

  //  remove user

  onRemoveClick() {
    const user = this.auth.user$.getValue();
    if (!user) {
      console.error('SettingsComponent.onRemoveClick => !user', user);
    }

    this.confirmationService
      .create(
        'Czy na pewno chcesz usunąć konto?',
        'Wszystkie Twoje dane zostaną bezpowrotnie usunięte',
        {
          confirmText: 'Tak, usuń',
          declineText: 'Anuluj',
        }
      )
      .subscribe(resolver => {
        if (resolver.resolved) {
          this.confirmationService
            .create(
              'Potwierdź usunięcie konta',
              'Rozumiem, że czynności tej nie da się odwrócić i wszystkie dane powiązane z moim kontem, w tym przepisy, prywatne składniki, diety oraz wszystkie informacje jakie zapisałem w aplikacjie zostaną usunięte bez możliwości ich odzyskania.',
              {
                confirmText: 'Rozumiem, usuwam konto',
                declineText: 'Nie chcę stracić danych',
              }
            )
            .subscribe(_resolver => {
              if (_resolver.resolved) {
                user
                  .delete()
                  .then(_ => {})
                  .catch(data => {
                    if (data.code === 'auth/requires-recent-login') {
                      this.toastr.info(
                        'Wystąpił błąd podczas zamykania konta. Prosimy, przeloguj się i usuń konto ponownie.'
                      );
                    } else {
                      this.toastr.info(
                        'Wystąpił błąd podczas zamykania konta. Prosimy, skontaktuj się z nami na adres pomoc@dieta17.pl.'
                      );
                    }
                  });
              }
            });
        }
      });
  }

  //  color options

  setColor(color) {
    this.settingsService.updateItem({
      themeColor: color,
    });
  }
}
