import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { OverlayService } from '../overlay/overlay.service';

@Component({
  moduleId: module.id,
  selector: 'f-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @Input() menuPosition = 'right';

  public isDropdownVisible = false;

  constructor(
    private _elementRef: ElementRef,
    private overlayService: OverlayService,
    private cd: ChangeDetectorRef
  ) {}

  @HostListener('document:keydown', ['$event'])
  public onKeyDown($event) {
    if (this.isDropdownVisible && $event.keyCode === 27) {
      this.isDropdownVisible = false;
      this.overlayService.hide();
    }
  }

  ngOnInit() {}

  toggleMenu($event) {
    $event.stopPropagation();
    this.isDropdownVisible = !this.isDropdownVisible;

    if (this.isDropdownVisible) {
      this.overlayService.show({
        clickCallback: () => {
          this.isDropdownVisible = false;
          this.cd.markForCheck();
          this.cd.detectChanges();
        },
      });
    }

    this.cd.detectChanges();
  }
}
