import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  public user$: BehaviorSubject<any> = new BehaviorSubject(false);
  public loaded$ = new BehaviorSubject(false);
  public user;

  //  https://firebase.google.com/docs/reference/js/firebase.auth.Auth

  constructor(private afAuth: AngularFireAuth, private router: Router) {
    this.afAuth.authState.subscribe(auth => {
      // console.log("%c" + 'AuthService', "background:yellow; color: black; padding:2px 20px; ")
      // console.log('AuthService:: auth change: ', auth)
      this.user = auth;
      this.user$.next(auth);
      this.loaded$.next(true);
    });
  }

  login(username: string, password: string) {
    const auth = firebase.auth().signInWithEmailAndPassword(username, password);
    // auth.catch(this.helpers.handleFirebaseError)
    return auth;
  }

  register(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  logout() {
    return this.afAuth.auth.signOut();
  }

  loginWithGoogle() {
    // Using a popup
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    return this.afAuth.auth.signInWithPopup(provider);
    // .then((result) => {
    //   // This gives you a Google Access Token.
    //   const token = result.credential.accessToken
    //   // The signed-in user info.
    //   const user  = result.user
    //   console.log('loginWithGoogle success: token, user', token, user)
    // })
  }

  loginWithFacebook() {
    // Using a popup
    const provider = new firebase.auth.FacebookAuthProvider();
    // provider.addScope('user_birthday')
    return firebase.auth().signInWithPopup(provider);
    // .then((result) => {
    //   // This gives you a Facebook Access Token.
    //   const token = result.credential.accessToken
    //   // The signed-in user info.
    //   const user  = result.user
    //   console.log('loginWithFacebook success: token, user', token, user)
    // })
  }

  loginWithGithub() {
    // Using a popup
    const provider = new firebase.auth.GithubAuthProvider();
    provider.addScope('repo');
    return firebase.auth().signInWithPopup(provider);
    //   .then((result) => {
    //     // This gives you a GitHub Access Token.
    //     const token = result.credential.accessToken
    //     // The signed-in user info.
    //     const user  = result.user
    //     console.log('loginWithGithub success: token, user', token, user)
    //   })
    //   .catch((error: any) => {
    //     // Handle Errors here.
    //     const errorCode    = error.code
    //     const errorMessage = error.message
    //     // The email of the user's account used.
    //     const email        = error.email
    //     // The firebase.auth.AuthCredential type that was used.
    //     const credential   = error.credential
    //     if (errorCode === 'auth/account-exists-with-different-credential') {
    //       alert('You have signed up with a different provider for that email.')
    //       // Handle linking here if your app allows it.
    //     } else {
    //       console.error(error)
    //     }
    //   })
  }

  isLoggedIn(): boolean {
    return !!this.user;
  }

  sendPasswordResetEmail(email) {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }
}
