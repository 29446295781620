import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'f-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() size: 'default' | 'small' | 'large' | 'large-x' | 'large-xx' =
    'default';
  @Input() name: string;
  @Input() faName: string;
  @Input() faType: 'fad' | 'fal' | 'fas' | 'far' = 'fal';
  @Input() color: string;
  @Input() pulse = false;

  //  second "badge" icon (plus, check or minus)
  @Input() withBadgeCheckbox = false;
  @Input() badgeChecked = false;
  //  overlay checkbox / minus icon
  @Input() withCheckbox = false;
  @Input() checked = false;

  @HostBinding('style.fontSize')
  get iconSize() {
    switch (this.size) {
      case 'small':
        return '.7em';
      case 'large':
        return '1.2em';
      case 'large-x':
        return '1.5em';
      case 'large-xx':
        return '2em';
      default:
        return '1em';
    }
  }

  @HostBinding('style.color')
  get iconColor() {
    if (this.color && this.color.charAt(0) === '#') {
      return this.color;
    }
    switch (this.color) {
      case 'primary':
        return '#4c58fb';
      default:
        return '';
    }
  }

  @HostBinding('class.pulse')
  get classPulse() {
    return this.pulse;
  }

  @HostBinding('class.withBadgeCheckbox')
  get classBadgeCheckbox() {
    return this.withBadgeCheckbox;
  }

  @HostBinding('class.badgeChecked')
  get classBadgeChecked() {
    return !!this.badgeChecked;
  }

  @HostBinding('class.withCheckbox')
  get classWithCheckbox() {
    return this.withCheckbox;
  }

  @HostBinding('class.checked')
  get classChecked() {
    return !!this.checked;
  }

  constructor() {}

  ngOnInit() {}
}
