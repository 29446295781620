import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'f-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSearchComponent implements OnInit {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() initialValue = '';

  @Output() onSearchChange = new EventEmitter();

  searchText = '';

  constructor() {}

  ngOnInit() {
    this.searchText = this.initialValue;
  }

  setSearchText(text) {
    this.searchText = text;
    this.onSearchChange.emit(text);
  }
}
