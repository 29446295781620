import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { each, filter, includes } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { FirebaseCollectionService } from './firebase-collection.service';

export interface TagFilter {
  viewId: string;
  tagId: string;
}

@Injectable()
export class TagFilterService extends FirebaseCollectionService {
  public groupedItems$: BehaviorSubject<object> = new BehaviorSubject({});

  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase
  ) {
    super('tag-filter', _authService, _db);
    // console.warn('TagFilterService::constructor')
  }

  get dbOptions() {
    return null;
  }

  parseData(items: TagFilter[]): TagFilter[] {
    // console.warn('TagFilterService::parseData')
    return items.map(item => this.parseItem(item));
  }

  parseItem(item: TagFilter): TagFilter {
    return item;
  }

  getSelectedTags(viewId: string) {
    if (!viewId) {
      throw new Error('TagFilterService::getSelectedTags, !viewId');
    }
    this.loadItem(viewId);
    return this.item$.pipe(
      map(data => {
        if (data && data.tags) {
          return Object.keys(data.tags);
        }
        return [];
      })
    );
  }

  toggleTag(viewId: string, tagId: string) {
    return this.toggleTags(viewId, [tagId]);
  }

  toggleTags(viewId: string, tagIds: Array<string>) {
    //  I assume that user already loaded selected tags for viewId with
    //  this.getSelectedTags(viewId)
    if (!viewId) {
      throw new Error('TagFilterService::toggleTags, !viewId');
    }
    let selectedTags = (this.item$.getValue() || {}).tags;

    if (!selectedTags) {
      selectedTags = {};
    }
    each(tagIds, tagId => {
      if (selectedTags[tagId]) {
        selectedTags[tagId] = null;
      } else {
        selectedTags[tagId] = true;
      }
    });
    return this.updateItem({
      tags: selectedTags,
    });
  }
}
