import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { TranslateFirebaseMessagePipe } from './translate-firebase-message.pipe';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UserRoutingModule } from './user-routing-module';
import { SettingsComponent } from './settings/settings.component';
import { ForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';

@NgModule({
  imports: [SharedModule, UserRoutingModule],
  declarations: [
    LoginComponent,
    RegisterComponent,
    TranslateFirebaseMessagePipe,
    SettingsComponent,
    ForgotPasswordPageComponent,
  ],
  exports: [],
  providers: [],
})
export class UserModule {}
