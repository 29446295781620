import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { sortBy } from 'lodash';

import { AuthService } from './auth.service';
import { FirebaseCollectionService } from './firebase-collection.service';
import { TagService } from './tag.service';

@Injectable()
export class DietService extends FirebaseCollectionService {
  constructor(
    private _authService: AuthService,
    private tagService: TagService,
    private _db: AngularFireDatabase
  ) {
    super('diet', _authService, _db);
    // console.warn('DietService::constructor')
  }

  parseData(items) {
    // console.warn('DietService::parseData')
    return items.map(item => this.parseItem(item));
  }

  parseItem(item) {
    //  get tags from service
    item.tags = this.fillRefs(item.tag_ids, this.tagService);
    return item;
  }

  sortData(items) {
    return sortBy(items, item => {
      if (item.position) {
        return parseInt(item.position, 10);
      }
      return '' + (item.name || '').toLowerCase();
    });
  }
}
