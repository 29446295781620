import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'f-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent implements OnInit {
  static readonly DEFAULT_DESCRIPTION = `
    Wygląda na to, że ten widok nie zawiera żadnych informacji`;

  @Input() title = 'Brak danych';
  @Input() description;
  @Input() buttonText = '';
  @Input() icon = '';
  @Input() inline = false;

  @Output() onClick = new EventEmitter();

  @HostBinding('class.inline')
  get classBInline() {
    return !!this.inline;
  }

  constructor() {}

  ngOnInit() {}

  get _description() {
    if (this.description !== null) {
      return this.description;
    }
    return EmptyStateComponent.DEFAULT_DESCRIPTION;
  }
}
