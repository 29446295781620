import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { find, filter, differenceWith, isEqual } from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'f-tagger',
  templateUrl: './tagger.component.html',
  styleUrls: ['./tagger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaggerComponent implements OnInit {
  //  Available tags to pick from
  @Input() tags: Array<
    | {
        name: string;
        value: string;
      }
    | string
  >;
  //  Selected tags
  @Input() selected: Array<string> = [];
  //  Allow to select only one value
  @Input() onlyOne = false;
  //  service
  @Input() service;

  //  When user change selection
  //  emits: selected tags
  @Output() onTagsChange = new EventEmitter();

  @ViewChild('newTagInput', { static: false }) newTagInput;

  public isInputVisible = false;
  public adding = false;

  constructor() {}

  ngOnInit() {
    // console.group('TaggerComponent')
    // console.log('this.tags', this.tags);
    // console.log('this.selected', this.selected);
    // console.groupEnd()

    //  remove selected empty values
    this.selected = filter(this.selected, (item: any) => item);
  }

  isSelected(tag) {
    return find(this.selected, item => item === tag.value);
  }

  onTagClick(tag) {
    let selected;
    if (this.isSelected(tag)) {
      selected = filter(this.selected, item => item !== tag.value);
    } else {
      if (this.onlyOne) {
        selected = [tag.value];
      } else {
        selected = [...this.selected, tag.value];
      }
    }

    this.onTagsChange.emit(selected);
  }

  addNewItem($event, name) {
    this.adding = true;
    this.service
      .addItem({ name })
      .then(item => {
        let selected;
        if (this.onlyOne) {
          selected = [item.getKey()];
        } else {
          selected = [...this.selected, item.getKey()];
        }
        this.adding = false;
        this.onTagsChange.emit(selected);
        this.clearNewTagInput();
      })
      .catch(_ => {
        this.adding = false;
      });
  }

  clearNewTagInput() {
    this.newTagInput.nativeElement.value = '';
    //  hide input
    this.isInputVisible = false;
  }

  //  toggle input

  toggleInput() {
    this.isInputVisible = !this.isInputVisible;
    if (this.isInputVisible) {
      setTimeout(() => {
        this.newTagInput.nativeElement.focus();
      }, 0);
    }
  }
}
