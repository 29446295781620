import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'f-input-debounce',
  template: `
    <input
      #inputRef
      type="text"
      class=""
      [placeholder]="placeholder"
      [id]="id"
      (keydown.escape)="onEscape()"
      (keydown.enter)="onEnter()"
      [formControl]="input"
    />

    <f-icon
      *ngIf="icon"
      (click)="iconClick.emit()"
      [name]="icon"
      size="large-x"
    >
    </f-icon>
  `,
  styleUrls: ['./input-debounce.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDebounceComponent implements OnInit, OnDestroy, OnChanges {
  @Input() placeholder = '';
  @Input() id: string;
  @Input() icon = '';
  @Input() delay = 300;
  @Input() value = '';

  @Output() valueChannge: EventEmitter<string> = new EventEmitter();
  @Output() iconClick: EventEmitter<void> = new EventEmitter();
  @Output() enter: EventEmitter<string> = new EventEmitter();
  @Output() escape: EventEmitter<string> = new EventEmitter();

  @ViewChild('inputRef', { static: false }) inputRef;

  private ngUnsubscribe = new Subject();

  input = new FormControl();

  constructor() {}

  ngOnInit() {
    this.bindInputEvents();
  }

  ngOnChanges(changes) {
    if (changes.value) {
      this.input.setValue(this.value);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onEscape() {
    this.input.setValue('');
    this.escape.emit();
  }

  onEnter() {
    this.enter.emit(this.input.value);
  }

  getValue() {
    return this.inputRef.nativeElement.value;
  }

  reset() {
    this.input.setValue('');
  }

  private bindInputEvents() {
    this.input.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(this.delay),
        distinctUntilChanged()
      )
      .subscribe(data => {
        this.valueChannge.emit(data);
      });
  }

  public focus() {
    this.inputRef.nativeElement.focus();
  }
}
