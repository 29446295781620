import { each, isString } from 'lodash';

export function trimValues(object: any): any {
  each(Object.keys(object), key => {
    const val = object[key];
    if (isString(val)) {
      object[key] = trim(val);
    }
  });
  return object;
}

export function trim(str: string): string {
  return str.trim();
}
