import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { assign } from 'lodash';
import { ToastrService } from 'ngx-toastr';

import { MessagesService } from '../../../services/messages.service';
import { trimValues } from '../../helpers/trim';

@Component({
  selector: 'f-contact-form-dialog',
  templateUrl: './contact-form-dialog.component.html',
  styleUrls: ['./contact-form-dialog.component.scss'],
})
export class ContactFormDialogComponent implements OnInit {
  @Input() loading = false;

  @Output() submit = new EventEmitter();
  @Output() cancel = new EventEmitter();

  form;

  constructor(
    private fb: FormBuilder,
    private messagesService: MessagesService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<ContactFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  submitForm() {
    const formValue = trimValues(this.form.value);
    const message = assign({}, formValue, this.data);
    this.loading = true;

    this.messagesService.addItem(message).then(_ => {
      this.toastr.success(
        'Odpowiemy najszybciej jak to bedzie możliwe',
        'Wiadomosc została wysłana'
      );
      this.loading = false;
      this.dialogRef.close(formValue);
    });
  }

  onCancel() {
    this.form.reset();
    this.dialogRef.close(null);
  }
}
