import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

import { AuthService } from './auth.service';
import { FirebaseCollectionService } from './firebase-collection.service';

@Injectable()
export class MessagesService extends FirebaseCollectionService {
  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase
  ) {
    super('messages', _authService, _db);
  }

  get dbOptions() {
    return null;
  }

  parseData(items) {
    // console.warn('MessagesService::parseData', items)
    return items.map(item => this.parseItem(item));
  }

  parseItem(item) {
    // console.warn('MessagesService::parseItem', item)
    return item;
  }
}
