import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'f-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() backLink;
  @Input() editLink;
  @Input() title;
  @Input() icon;
  @Input() size;

  constructor(private location: Location, private router: Router) {}

  backLinkClick() {
    if (this.backLink) {
      this.router.navigate(this.backLink);
    } else {
      this.location.back();
    }
  }

  ngOnInit() {}
}
