import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FirebaseStorageService } from '../../../services/firebase-storage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'f-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: any;

  @Output() uploaded = new EventEmitter();

  user;
  loading;

  constructor(
    private firebaseStorage: FirebaseStorageService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  fileChange($event) {
    // console.log('$event', $event)
    // console.log('$event.target.files', $event.target.files)
    const files = $event.target.files;
    const file = files[0];
    this.loading = true;

    this.firebaseStorage
      .uploadFile(file)
      .then(data => {
        this.uploaded.emit(data);
        this.loading = false;
      })
      .catch(() => {
        console.warn('this.firebaseStorage.uploadFile error from promise');
        this.loading = false;
        this.toastr.warning(
          `Wystąpił błąd podczas wgrywania pliku. Proszę spróbować ponownie później.`,
          'Błąd wgrywania pliku'
        );
      });
  }
}
