import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { each, find, uniq } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { FirebaseCollectionService } from './firebase-collection.service';

export interface TagType {
  name: string;
  group: string;
  color: string;
  position: number;
}

@Injectable()
export class TagService extends FirebaseCollectionService {
  public groupedItems$: BehaviorSubject<object> = new BehaviorSubject({});

  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase
  ) {
    super('tag', _authService, _db);
    // console.warn('TagService::constructor')
  }

  get dbOptions() {
    return null;
  }

  parseData(items: TagType[]): TagType[] {
    const parsedItems = items.map(item => this.parseItem(item));
    //  group tags
    const groupedItems = this.groupTagsByGroup(parsedItems);
    this.groupedItems$.next(groupedItems);
    // console.warn('TagService::parseData tagItems', parsedItems)
    // console.warn('TagService::parseData groupedItems', groupedItems)
    return parsedItems;
  }

  parseItem(item: TagType): TagType {
    return item;
  }

  public groupTagsByGroup(parsedItems) {
    const groupedItems = {};
    each(parsedItems, tag => {
      const groupId = tag.group || '';
      if (!groupedItems[groupId]) {
        groupedItems[groupId] = [];
      }
      groupedItems[groupId].push(tag);
      return tag;
    });
    // sort items in each group
    each(groupedItems, (groupItems, groupId) => {
      groupedItems[groupId] = this.sortData(groupItems);
    });
    return groupedItems;
  }

  getByTags(_items, tagId) {
    const items = _items.filter(item => {
      const tagIds = item.tag_ids || [];
      return tagIds.indexOf(tagId) > -1;
    });
    // console.warn('getByTags => return', items)
    return items;
  }

  getTagsFromItems(
    items
  ): Array<{
    count: number;
    tagId: string;
    tag: TagType;
  }> {
    const tags = [];
    each(items, item => {
      const tagIds = item.tag_ids || [];
      let tag;
      each(tagIds, tagId => {
        tag = find(tags, tagWithMeta => {
          return tagWithMeta.tagId === tagId;
        });
        if (tag) {
          tag.count++;
        } else {
          tags.push({
            count: 1,
            tagId: tagId,
            tag: this.getItem(tagId),
          });
        }
      });
    });
    // tags = this.groupTagsByGroup(tags)
    console.warn('getTagsFromItems => return', tags);
    return tags;
  }

  getTagIdsFromItems(items): Array<string> {
    let tags = [];
    each(items, item => {
      const tagIds = item.tag_ids || [];
      tags = [...tags, ...tagIds];
    });
    tags = uniq(tags);
    // console.warn('getTagIdsFromItems => return', tags)
    return tags;
  }
}
