import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MenuService } from './services';

import { AuthService } from './services/auth.service';
import { LocalStorageService } from './services/local-storage.service';
import { SettingsService } from './services/settings.service';

@Component({
  moduleId: module.id,
  selector: 'f-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  user: any;
  //  options for https://github.com/Jaspero/ng2-confirmations
  //  docs https://github.com/Jaspero/ng2-confirmations#options
  jasperoConfirmationsOptions = {
    overlay: true,
    overlayClickToClose: false,
    showCloseButton: true,
    confirmText: 'Tak',
    declineText: 'Nie',
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private ls: LocalStorageService,
    private settingsService: SettingsService,
    private overlayContainer: OverlayContainer,
    private menuService: MenuService
  ) {
    // console.warn('AppComponent::constructor')
  }

  ngOnInit() {
    // console.warn('AppComponent::ngOnInit')

    this.authService.user$.subscribe(user => {
      // console.log('AppComponent::constructor::authService.subscribe user changed', user)

      if (!this.user && user) {
        // if user was not logged in and tried to load specific url,
        // redirect to that url after successful login
        // possible scenario: page refreshing when at startup user is not logged
        //  in (session is not checked yet with backend) oraz session expired
        const nextUrl = this.ls.get('router.next.url') || '/dashboard';
        console.log('nextUrl', nextUrl);
        this.router.navigate([`/${nextUrl}`]);
        this.ls.set('router.next.url', null);
        this.init();
      }

      if (this.user && !user) {
        this.logout();
      }

      this.user = user;
    });

    this.authService.loaded$.subscribe(loaded => {
      if (loaded && !this.user) {
        document.body.classList.add('app-loaded');
      }
    });
    this.settingsService.loaded$.subscribe(loaded => {
      if (loaded) {
        document.body.classList.add('app-loaded');
      }
    });
  }

  init() {
    this.settingsService.loadItem('app');

    this.settingsService.item$
      .pipe(filter(data => data))
      .subscribe(appSettings => {
        document.body.classList.remove('theme_blue');
        document.body.classList.remove('theme_purple');
        document.body.classList.remove('theme_mako');
        if (appSettings.themeColor) {
          const themeClassName = 'theme_' + appSettings.themeColor;
          document.body.classList.add(themeClassName);
          this.overlayContainer
            .getContainerElement()
            .classList.add(themeClassName);
        }
      });
  }

  logout() {
    this.authService.logout().then(_ => {
      this.ls.clear();
      this.router.navigate(['/logowanie']);
    });
  }

  // events

  toggleMenu() {
    this.menuService.toggleMenu();
  }
}
