import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'f-filter-picker-dialog',
  templateUrl: './filter-picker-dialog.component.html',
  styleUrls: ['./filter-picker-dialog.component.scss'],
})
export class FilterPickerDialogComponent implements OnInit {
  filters;

  constructor(
    private dialogRef: MatDialogRef<FilterPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.filters = data.filters;
  }

  ngOnInit() {}

  onFiltersChange(selectedFilters) {
    this.dialogRef.close(selectedFilters);
  }

  public onCancel() {
    this.dialogRef.close(null);
  }
}
