import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { find, indexOf } from 'lodash';

import { LocalStorageService } from '../../../services/local-storage.service';

export interface FilterSetItemType {
  name: string;
  value: any;
}

@Component({
  moduleId: module.id,
  selector: 'f-filter-set',
  templateUrl: './filter-set.component.html',
  styleUrls: ['./filter-set.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSetComponent implements OnInit {
  @Input() filters: Array<FilterSetItemType> = [];
  @Input() multiple = true;
  @Input() displayHeaderButtons = true;
  @Input() name;
  @Input() label = '';
  @Input() selectedFilters: Array<FilterSetItemType> = [];

  @Output() onFiltersChange = new EventEmitter();

  private dbId;
  public isCollapsed;

  constructor(private ls: LocalStorageService) {}

  ngOnInit() {
    this.dbId = 'filters_' + this.name;
    //  expand filters by default, but remember state between user visits
    this.isCollapsed = this.ls.get(this.dbId + '_isCollapsed') || false;
  }

  emitChange(selectedFilters) {
    if (!selectedFilters) {
      selectedFilters = this.selectedFilters;
    }
    this.onFiltersChange.emit(selectedFilters);
  }

  toggle(clickedFilter, $event) {
    let selectedFilters;
    if (this.isAllSelected() && this.multiple) {
      //  select clicked filter when all are selected
      selectedFilters = [clickedFilter];
    } else {
      //  toggle checked
      if (
        find(
          this.selectedFilters,
          filter => filter.value === clickedFilter.value
        )
      ) {
        selectedFilters = this.selectedFilters.filter(filter => {
          return filter.value !== clickedFilter.value;
        });
      } else {
        selectedFilters = [...this.selectedFilters, clickedFilter];
      }
    }

    // this.saveSelection()
    this.emitChange(selectedFilters);
  }

  selectAll() {
    // console.log('selectAll')
    // this.selectedFilters = this.filters.map(filter => filter.value)
    // this.saveSelection(this.filters.map(filter => filter.value))
    this.emitChange(this.filters);
  }

  deselectAll() {
    // console.log('deselectAll')
    // this.selectedFilters = []
    // this.saveSelection()
    this.emitChange([]);
  }

  isPartiallySelected() {
    return this.selectedFilters.length;
  }

  isAllSelected() {
    return this.multiple && this.selectedFilters.length === this.filters.length;
  }

  isSelected(item) {
    return find(this.selectedFilters, filter => filter.value === item.value);
  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
    this.ls.set(this.dbId + '_isCollapsed', this.isCollapsed);
  }
}
